



















import mixins from 'vue-typed-mixins'
import Gallery from '@/calendesk/sections/section/gallery/mixins/Gallery'
export default mixins(Gallery).extend({
  name: 'Gallery2',
  data () {
    return {
      model: 0
    }
  }
})
