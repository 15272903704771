import mixins from 'vue-typed-mixins'
import Section from '@/calendesk/sections/section/mixins/Section'
import { plainToClass } from 'class-transformer'
import DefaultImage from '@/calendesk/models/DTO/Response/DefaultImage'

export default mixins(Section).extend({
  name: 'Gallery',
  methods: {
    plainToClassImageObject (image: object) {
      return plainToClass(DefaultImage, image)
    }
  }
})
